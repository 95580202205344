<template>
  <div>
    <!-- <div class="tip" v-if="type == 1">
      {{ $i18n.locale == "zh" ? "隐私说明" : "Privacy statement" }}
    </div> -->
    <div class="tip">
      {{ $i18n.locale == "zh" ? "网站声明" : "Website Statement" }}
    </div>
    <div v-html="info"></div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：隐私说明，网站申明
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-24 10:08
 */
export default {
  data() {
    return {
      type: "",
      info: "",
    };
  },
  mounted() {
    this.type = this.$route.query.type; // 1:隐私说明  2:网站申明
    this.getDetail();
  },
  methods: {
    /**
     * 隐私说明，网站申明
     * 刘嘉鑫
     * 2022-8-24
     */
    getDetail() {
      let url;
      if (this.type == 1) {
        url = "/Index/yins";
      }
      if (this.type == 2) {
        url = "/Index/shenm";
      }
      this.$request({
        url: url,
        data: {},
      }).then((res) => {
        if (this.type == 1) {
          this.info = res.yins;
        }
        if (this.type == 2) {
          this.info = res.shenm;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.appCont {
  background: #f6f6f6;
  padding: 80px 310px;
}

.tip {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #1a2a60;
  margin-bottom: 60px;
}
</style>